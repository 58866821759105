import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider } from '../components/layout';
import { IFileImage } from '../@types';
import {
  ASafe4You,
  ProductShowcase,
  Categories,
  FindASafe,
  LifeStyleBanner,
  MainBanner,
  SignMeUp,
  Stories,
  HomeBanner,
} from '../components/sections';
import { useTableOrMobile } from '../hooks';
import { IAllBlogs } from './blog';

const query = graphql`
  query {
    strapiMainBanner {
      title
      description
      action
      backgroundColor
      backgroundImage {
        alternativeText
        ext
        hash
        url
        formats {
          large {
            ext
            hash
            url
          }
          medium {
            ext
            hash
            url
          }
          small {
            ext
            hash
            url
          }
          thumbnail {
            ext
            hash
            url
          }
        }
      }
      frontImage {
        alternativeText
        ext
        hash
        url
      }
      frontVideo {
        url
        mime
      }
      showVideo
    }
    strapiBestsellers {
      title
      description
      enabled
      action
    }
    allStrapiCannonSafes {
      edges {
        node {
          id
          displayName
          popular
          slug
          specifications
          firearmCapacity
          reviews {
            id
            title
            value
            description
          }
          safeCategory {
            title
          }
          series {
            title
          }
          displayImage {
            alternativeText
            ext
            hash
            url
            formats {
              thumbnail {
                ext
                hash
                url
              }
            }
          }
        }
      }
    }
    strapiASafeForYou {
      title
      description
      enabled
    }
    allStrapiLifeStyleVariation {
      edges {
        node {
          id
          published_at
          strapiId
          title
          fullImage {
            id
            alternativeText
            caption
            created_at
            ext
            hash
            height
            mime
            name
            provider
            size
            updated_at
            url
            width
            formats {
              thumbnail {
                ext
                hash
                height
                mime
                name
                width
                url
                size
              }
            }
          }
        }
      }
    }
    strapiLifestyleBanner {
      action
      description
      title
      enabled
      media {
        url
        ext
      }
      videoLink
      videoPreviewImage {
        url
      }
    }
    strapiCategories {
      enabled
      description
      title
    }
    allStrapiSafeCategory {
      nodes {
        title
        identifierImage {
          id
          alternativeText
          caption
          created_at
          ext
          hash
          height
          mime
          name
          provider
          size
          updated_at
          url
          width
          formats {
            thumbnail {
              ext
              hash
              height
              mime
              name
              width
              url
              size
            }
          }
        }
      }
    }
    strapiFindASafe {
      title
      description
      enabled
      action
      image {
        id
        height
        width
        url
      }
    }
    strapiStories {
      title
      description
      enabled
    }
    allStrapiBlogEntries(sort: { order: DESC, fields: created_at }) {
      nodes {
        created_at
        blogCategory {
          Name
        }
        homeBtnText
        showInHome
        title
        slug
        previewText
        mainMedia {
          alternativeText
          ext
          hash
          url
          formats {
            thumbnail {
              ext
              hash
              url
            }
          }
        }
      }
    }
    strapiSignMeUp {
      acknowledgment
      action
      description
      enabled
      placeholder
      text
      background {
        id
        alternativeText
        caption
        created_at
        ext
        hash
        height
        mime
        name
        provider
        size
        updated_at
        url
        width
        formats {
          thumbnail {
            ext
            hash
            height
            mime
            name
            width
            url
            size
          }
        }
      }
    }
    strapiHomeBanner {
      bannerEnabled
      bannerTextColor
      bannerTitle
      bannerBodyText
      bannerBackgroundColor
    }
  }
`;

interface SpecificationBase {
  id: number;
  strapi_component:
    | 'safe.capacity'
    | 'safe.capability'
    | 'safe.features'
    | 'safe.design';
}

interface CapacityGun {
  displayName: string;
  icon?: IFileImage;
  id: number;
  itemCapacity: number;
}

interface CapacitySpec {
  displayName: string;
  descriptionName: string;
  id: number;
}

export interface SpecificationCapacity extends SpecificationBase {
  fullWeight: string;
  intDepth: string;
  intHeight: string;
  intWWidth: string;
  image?: IFileImage;
  guns: Array<CapacityGun>;
  specs: Array<CapacitySpec>;
  strapi_component: 'safe.capacity';
}

interface Capability {
  id: number;
  displayName: string;
  icon?: IFileImage;
}

interface Feature {
  descriptionName: string;
  displayName: string;
  icon?: IFileImage;
  id: number;
}

export interface SpecificationCapability extends SpecificationBase {
  items: Array<Capability>;
  strapi_component: 'safe.capability';
}

export interface SpecificationFeature extends SpecificationBase {
  depthWHandle: string;
  extDepth: string;
  extHeight: string;
  extWidth: string;
  featureItem: Array<Feature>;
  id: number;
  image: IFileImage;
  strapi_component: 'safe.features';
}

interface Design {
  description: string;
  displayName: string;
  id: number;
  optionalDescriptionIcon?: IFileImage;
}

export interface SpecificationDesign extends SpecificationBase {
  designItem: Array<Design>;
  doorThickness: string;
  id: number;
  image?: IFileImage;
  strapi_component: 'safe.design';
  wallThickness: string;
}

export interface Media {
  id: number;
  enabled: boolean;
  title: string;
  description: string;
  media: Array<IFileImage>;
}

export interface Review {
  id: number;
  title: string;
  description: string;
  value: number;
}

// export type ISpecification = SpecificationCapacity | SpecificationCapability | SpecificationFeature | SpecificationDesign;

export interface SpecificationItem {
  id: number;
  image?: IFileImage;
  name: string;
  value: string;
}

export interface ISpecification {
  id: number;
  image?: IFileImage;
  main: boolean;
  title: 'Features' | 'Capacity' | 'Capability' | 'Design' | string;
  hightlight: Array<SpecificationItem>;
  list: Array<SpecificationItem>;
  support: Array<SpecificationItem>;
  regulationParagraph: string;
  regulationActive: boolean;
}

export interface ICannonSafe {
  averagePrice: number;
  coverImage?: IFileImage;
  displayImage?: IFileImage;
  displayName: string;
  description: string;
  bestFor: string;
  popular?: boolean;
  id: number;
  lifeStyleVideoLink: string;
  firearmCapacity?: number;
  weight?: number;
  extDimensions?: {
    width: number;
    height: number;
    depth: number;
  };
  intDimensions?: {
    width: number;
    height: number;
    depth: number;
  };
  lifeStyleVariation: {
    title: string;
  };
  lifeStyleVariations: Array<ILifeStyleVariationArray>;
  purchaseMedia: Media;
  lifestyleMedia: Media;
  reviews: Array<Review>;
  safeCategory: {
    title: string;
  };
  series: {
    title: string;
    description: string;
  };
  features: {
    floodProtection: boolean;
    basicSecureStorage: boolean;
    electronicsCapable: boolean;
    fireProtection: boolean;
    firearmCapable: boolean;
    heirloomsCapable: boolean;
    jewleryCapable: boolean;
    memorabiliaCapable: boolean;
    priceRange: 'string';
    theftProtection: boolean;
    waterproofText: string;
    convertibleInteriorText: string;
  };
  slug: string;
  detailImages?: {
    image: IFileImage;
    type: string;
    imageDescription: string;
  }[];
  type?: string;
  specifications: Array<ISpecification>;
  retailers: IRetailer[];
  manual: IFileImage;
  lifeStyleVideoPreviewImage: IFileImage;
  UPC: string;
  actionBanner: string;
  activeAction: boolean;
  shopOnlineLink: string;
  modelSKU: string;
  retailerCategory: string;
  regulationParagraph: string;
  regulationActive: boolean;
}

export interface ILifeStyleVariationArray {
  title: string;
}

export interface IRetailer {
  displayName: string;
}
export interface IResponseMainBanner {
  strapiMainBanner: {
    title?: string;
    description?: string;
    action?: string;
    actionURL?: string;
    backgroundImage?: IFileImage;
    backgroundColor?: string;
    frontImage?: IFileImage;
    frontVideo?: IFileImage;
    showVideo?: boolean;
  };
}

export interface IResponseBestsellers {
  strapiBestsellers: {
    title: string;
    enabled: boolean;
    description: string;
    action: string;
  };
  allStrapiCannonSafes: {
    edges: Array<{ node: ICannonSafe }>;
  };
}

interface ILifestyleVariation {
  node: {
    id: string;
    strapiId: number;
    title: string;
    fullImage: IFileImage;
  };
}

export interface IResponseASafeForYou {
  strapiASafeForYou: {
    title: string;
    enabled: boolean;
    description: string;
  };
  allStrapiLifeStyleVariation: {
    edges: Array<ILifestyleVariation>;
  };
}

export interface IResponseLifestyleBanner {
  strapiLifestyleBanner: {
    title: string;
    enabled: boolean;
    description: string;
    action: string;
    media?: IFileImage;
    videoPreviewImage: IFileImage;
    videoLink?: string;
  };
}

export interface SafeCategory {
  title: string;
  identifierImage: IFileImage;
}

export interface IResponseCategories {
  strapiCategories: {
    title: string;
    enabled: boolean;
    description: string;
  };
  allStrapiSafeCategory: {
    nodes: Array<SafeCategory>;
  };
}

export interface IResponseFindASafe {
  strapiFindASafe: {
    title: string;
    enabled: boolean;
    description: string;
    action: string;
    image: IFileImage;
  };
}

export interface IResponseStories {
  strapiStories: {
    title: string;
    description: string;
    enabled: boolean;
  };
  allStrapiBlogEntries: {
    nodes: Array<IAllBlogs>;
  };
}

export interface ISignMeUpResponse {
  strapiSignMeUp: {
    acknowledgment: string;
    action: string;
    description: string;
    enabled: boolean;
    placeholder: string;
    text: string;
    background: IFileImage;
  };
}

export interface IHomeBanner {
  strapiHomeBanner: {
    bannerTitle: string;
    bannerBodyText: string;
    bannerBackgroundColor: string;
    bannerTextColor: string;
    bannerEnabled: boolean;
  }
}

interface IData
  extends IResponseMainBanner,
    IResponseBestsellers,
    IResponseASafeForYou,
    IResponseLifestyleBanner,
    IResponseCategories,
    IResponseFindASafe,
    ISignMeUpResponse,
    IResponseStories,
    IHomeBanner {}

const IndexPage: React.FC<PageProps> = (props: PageProps) => {
  const data = useStaticQuery<IData>(query);
  const [isMobile] = useTableOrMobile();

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <HomeBanner strapiHomeBanner={data.strapiHomeBanner} />
      <MainBanner strapiMainBanner={data.strapiMainBanner} />
      <ProductShowcase
        title={data.strapiBestsellers.title}
        enabled={data.strapiBestsellers.enabled}
        description={data.strapiBestsellers.description}
        action={data.strapiBestsellers.action}
        list={data.allStrapiCannonSafes.edges.map((li) => li.node)}
        applyMobileDesign
      />
      <ASafe4You
        strapiASafeForYou={data.strapiASafeForYou}
        allStrapiLifeStyleVariation={data.allStrapiLifeStyleVariation}
      />
      <LifeStyleBanner strapiLifestyleBanner={data.strapiLifestyleBanner} />
      {!isMobile ? (
        <Categories
          strapiCategories={data.strapiCategories}
          allStrapiSafeCategory={data.allStrapiSafeCategory}
        />
      ) : (
        <></>
      )}
      <FindASafe strapiFindASafe={data.strapiFindASafe} />
      <Stories
        strapiStories={data.strapiStories}
        allStrapiBlogEntries={data.allStrapiBlogEntries}
      />
      <SignMeUp strapiSignMeUp={data.strapiSignMeUp} />
    </LayoutProvider>
  );
};

export default IndexPage;
